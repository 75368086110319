<template>
  <div>
    <NewTokenCard />
  </div>
</template>

<script>
import NewTokenCard from "../components/NewTokenCard.vue";
export default {
  components: { NewTokenCard },
};
</script>
