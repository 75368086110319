<template>
  <div>
    <SearchTokenCard />
  </div>
</template>

<script>
import SearchTokenCard from "../components/SearchTokenCard.vue";
export default {
  components: { SearchTokenCard },
};
</script>
